import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { tokens } from "../theme";

export default function CustomToolbar({ setOpenDialog }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Box sx={{ flexGrow: 1 }} />
      {setOpenDialog ? (
        <Box>
          <Button
            sx={{
              color: `${colors.primary[400]} !important`,
              backgroundColor: colors.greenAccent[300],
            }}
            onClick={() => setOpenDialog(true)} // Abre o diálogo ao clicar
          >
            Adicionar
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </GridToolbarContainer>
  );
}
