import React, { useState, useEffect, useContext } from "react";
import { getData } from "../../../utils/functions";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { AuthContext } from "../../../context/AuthContext";
import { tokens } from "../../../theme";
import Header from "../../../components/Admin/Title/Title";
import NumberTotal from "../../../components/Admin/Cards/NumberTotal";
import Admin from "../Admin";
import CustomToolbar from "../../../utils/CustomToolbar.js";
import UserFormDialog from "./UserFormDialog";

export default function Users() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(AuthContext);

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // Controle do diálogo

  const filteredUsers = users
    .filter((user) => {
      return user?.name?.toLowerCase().includes(searchTerm.toLowerCase());
    })
    .map((data, index) => ({ ...data, id: index + 1 }));

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "phone", headerName: "Telefone", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "updatedBy", headerName: "Atualizado por", flex: 1 },
    { field: "identity", headerName: "Identidade", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "date", headerName: "Data", flex: 1 },
    { headerName: "Ação", flex: 1 },
  ];

  useEffect(() => {
    getData("users", setUsers, setIsLoading, setError);
  }, []);

  const customToolbar = () => {
    return <CustomToolbar setOpenDialog={setOpenDialog} />;
  };

  return (
    <Admin>
      <Box m="20px">
        <Header
          title="Usuários"
          subtitle="Lista de usuários cadastrados no sistema."
        />
        <Box display={"flex"}>
          <Box flex="0 0 30%">
            <NumberTotal route={"users"} />
          </Box>
        </Box>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]}`,
            },
          }}
        >
          <DataGrid
            checkboxSelection
            rows={filteredUsers}
            columns={columns}
            initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
            slots={{ toolbar: customToolbar }}
            pageSizeOptions={[5, 10, 25]}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
        <UserFormDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          user={user}
        />
      </Box>
    </Admin>
  );
}
