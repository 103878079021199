import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function UserFormDialog({ open, onClose, user }) {
  const [newUser, setNewUser] = useState({
    name: "",
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
    tel: "",
    email: "",
    password: "",
    identity: "",
    terms: true,
  });
  
  const [errors, setErrors] = useState({ phone: false, identity: false });
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" || name === "identity") {
      if (value.length > 15) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
        return;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
      }
    }
    setNewUser({ ...newUser, [name]: value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleSubmit = () => {
    console.log("Novo usuário:", newUser);
    onClose(); // Fecha o diálogo
    setNewUser({ name: "", phone: "", email: "", password: "", identity: "" });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar Novo Usuário</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Nome"
          type="text"
          fullWidth
          variant="outlined"
          value={newUser.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="phone"
          label="Telefone"
          type="text"
          fullWidth
          variant="outlined"
          value={newUser.phone}
          onChange={handleInputChange}
          error={errors.phone}
          helperText={errors.phone ? "Limite de 15 caracteres" : ""}
        />
        <TextField
          margin="dense"
          name="identity"
          label="Identidade"
          type="text"
          fullWidth
          variant="outlined"
          value={newUser.identity}
          onChange={handleInputChange}
          error={errors.identity}
          helperText={errors.identity ? "Limite de 15 caracteres" : ""}
        />
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={newUser.email}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="password"
          label="Senha"
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={newUser.password}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Enviar</Button>
      </DialogActions>
    </Dialog>
  );
}
